import { graphql, Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import BlogRoll from '../components/BlogRoll';
import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import CallToActionContent from '../components/UI/CallToActionContent';
import ContentHeader from '../components/UI/ContentHeader';
import SectionContainer from '../components/UI/SectionContainer';
import DataMarkdown from '../types/DataMarkdown';
import Image from '../types/Image';

interface PropsTemplate {
  title: string;
  subheading: string;
  image: Image | string;
  content: string;
  contentComponent?: Content | HTMLContent;
}

const PlataBlancaSection = styled.div`
  display: flex;
  justify-content: center;

  div {
    display: flex;
    padding: 10px;
    width: 890px;
    transition: all 0.3s ease;
    justify-content: center;
    align-items: center;
    border-radius: 1px solid gray;
    border-radius: 6px 20px 6px 6px;
    text-align: center;
    color: gray;
    border: 1px solid #e9e9e9;

    &:hover {
      transform: translateY(-7px);
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    }
  }
  img {
    height: 80px;
    width: 140px;
  }
`;
const ArticleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
`;
export const EducationPageTemplate: React.FC<PropsTemplate> = ({
  title,
  subheading,
  image,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <>
      <ContentHeader
        title={title}
        subheading={subheading}
        image={
          typeof image === 'string' ? image : image.childImageSharp.fluid.src
        }
      />
      <SectionContainer>
        <PageContent className="content" content={content} />
      </SectionContainer>

      <BlogRoll />

      <CallToActionContent />
    </>
  );
};

interface Props {
  html: string;
  frontmatter: {
    title: string;
    subheading: string;
    image: Image;
  };
}

const EducationPage = ({ data }: DataMarkdown<Props>) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <EducationPageTemplate
        title={post.frontmatter.title}
        subheading={post.frontmatter.subheading}
        image={post.frontmatter.image}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

export default EducationPage;

export const educationPageQuery = graphql`
  query EducationPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        subheading
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
