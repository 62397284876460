import { Link } from 'gatsby';
import React from 'react';
import styled from '../../styles/styled-components';
import SectionContainer from './SectionContainer';
import StartLoginButton from './StartLoginButton';

const CallToActionStyled = styled.div`
  background-color: ${props => props.theme.gray100};
`;
const SectionContainerStyled = styled(SectionContainer)`
  padding-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  & > * {
    margin-bottom: 32px;
  }
`;
const StartLoginButtonStyled = styled(StartLoginButton)`
  flex: 0;
`;
const FaqBlock = styled.div`
  text-align: center;
  flex: 1;
  display: flex;
  justify-content: center;
`;
const FaqContent = styled.div`
  width: 230px;
`;
const FaqTitle = styled.div`
  font-weight: 700;
  margin-bottom: 16px;
`;

const CallToActionContent = ({
  ...props
}: React.HTMLAttributes<HTMLElement>) => (
  <CallToActionStyled {...props}>
    <SectionContainerStyled>
      <StartLoginButtonStyled />
      <FaqBlock>
        <FaqContent>
          <FaqTitle>¿Tienes preguntas acerca de nuestro servicio?</FaqTitle>
          <div>
            Ingresa a nuestra sección de
            <br />
            <Link to="/faq">preguntas frecuentes</Link>
          </div>
        </FaqContent>
      </FaqBlock>
    </SectionContainerStyled>
  </CallToActionStyled>
);

export default CallToActionContent;
