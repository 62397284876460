import { graphql, Link, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from '../styles/styled-components';
import SectionContainer from './UI/SectionContainer';

interface ImageProps {
  src: string;
}

const Content = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const ArticleLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover {
    color: inherit;
  }
`;
const Article = styled.div`
  max-width: 280px;
  margin: 0 10px 20px;
  border-radius: 1px solid ${p => p.theme.gray200};
  border-radius: 6px 20px 6px 6px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
    transform: translateY(-5px);
  }
`;
const Text = styled.div`
  padding: 0 16px;
`;
const Title = styled.h3`
  font-weight: 600;
`;

interface Props {
  data: any;
}

const BlogRoll = (props: Props) => {
  const { data } = props;
  const { edges: posts } = data.allMarkdownRemark;

  return (
    <SectionContainer size="wide">
      <Content>
        {posts &&
          posts.map(({ node }: { node: any }) => (
            <ArticleLink to={node.fields.slug} key={node.id}>
              <Article>
                <Img fixed={node.frontmatter.image.childImageSharp.fixed} />

                <Text>
                  <Title>{node.frontmatter.title}</Title>
                </Text>
              </Article>
            </ArticleLink>
          ))}
      </Content>
    </SectionContainer>
  );
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                description
                image {
                  childImageSharp {
                    fixed(width: 280, height: 100, quality: 75) {
                      ...GatsbyImageSharpFixed_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <BlogRoll data={data} />}
  />
);
