import React from 'react';
import styled from '../../styles/styled-components';
import BackgroundSection from './BackgroundSection';
import IOText from './IOText';
import SectionContainer from './SectionContainer';

interface StyledProps {
  image: string;
}

const Header = styled.div`
  padding: 10px 20px 68px;
  position: relative;
`;
const Background = styled(BackgroundSection)`
  background-image: url(${(props: StyledProps) => props.image});
  background-size: cover;
  background-position: center;
  &:before {
    background: linear-gradient(
      to bottom,
      ${props => props.theme.primary300} 0%,
      ${props => props.theme.primary500} 100%
    );
    opacity: 0.8;
  }
`;
const HeaderContent = styled.div`
  position: relative;
  z-index: 100;
`;
const Title = styled.h1`
  color: #fff;
  margin-bottom: 48px;
`;
const Lead = styled.div`
  color: #fff;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.3;
  margin-bottom: 16px;
`;
const Divider = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  fill: #fff;
  height: 5vw;
  width: 100%;
`;

export interface Props {
  title: string;
  subheading?: string;
  image: string;
}

const ContentHeader: React.FC<Props> = ({ title, subheading, image }) => (
  <Header>
    <Background image={image} />
    <SectionContainer>
      <HeaderContent>
        <Title>{title}</Title>
        {!!subheading && <Lead>{subheading}</Lead>}
        <IOText />
      </HeaderContent>
    </SectionContainer>
    <Divider
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 10"
      preserveAspectRatio="none"
    >
      <polygon points="100 10 100 0 -4 10" />
    </Divider>
  </Header>
);

export default ContentHeader;
