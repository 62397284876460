import React from 'react';
import styled from '../../styles/styled-components';

const IOTextStyled = styled.div`
  color: ${props => props.theme.secondary500};
  background: linear-gradient(
    to bottom right,
    ${props => props.theme.secondary500},
    ${props => props.theme.primary200}
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  font-size: 20px;
`;
const IOText: React.FC<React.HTMLAttributes<HTMLElement>> = ({
  ...restProps
}) => <IOTextStyled {...restProps}>&#60; I 0 I 0 I 0 I 0 &#62;</IOTextStyled>;

export default IOText;
